.logo-list--heading {
	text-align: center;
}

.logo-list {
	align-items: center;
	display: block;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin: $strip-padding / 2 0 0;
}

.logo-list--block {
	display: block;
}
