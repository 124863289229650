.form--newsletter {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    label {
        display: block;
        margin-bottom: .5em;
        text-align: center;
        width: 100%;
    }

    input[type="text"],
    input[type="email"] {
        flex-grow: 1;
        flex-shrink: 1;
        margin: 0;
        width: auto;
    }

    button {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0 0 0 1em;
    }

}
