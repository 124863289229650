@import 'jqueryui';

/* =Input Styling
==========================================*/
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
textarea,
.field {
	background-color: $lgrey;
	border: 1px solid transparent;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	font-family: $fancy-sans;
	font-size: 1em;
	margin: 0;
	padding: .8em 1em;
	vertical-align: middle;
	width: 100%;

	transition: border-color $transition $easing;

	&:hover,
	&:focus,
	&:active {
		border-color: $grey;
		border-radius: 0;
	}

	&.error,
	&.is-invalid {
		border-color: $alert-red;
		background-color: $white;
		background-position: 99% center;
		background-repeat: no-repeat;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDREQkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDREQ0YwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjQ3ODRGRkE2RjA0QTExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERBRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+U8iT5wAAAedJREFUeNqk1U9I02Ecx/HtV3aIFAc1hcRDUoGXRAq0oNLA2CrsEFmHbikZu0iQYIFGYAiegkCpLipBxPpDEBMUzB0EhSG2LhG7hMR2GviPgUTvB57Bw8P3+U23B16HPX8+e/b8nt93wezZSMCnhXETF3AcB5BDCnH8Dq98ExcGHcFn8Ah3cdDni+fxnPDv9oAnTB7CKu6VCFXtChZy56LxUjt+jfuB8toSOth9wd7xWAWhqrUjYR/FRTwWJm+iIPT/w7bQf5ljiZnBg45dtKFX6H+LU8gIY8OEV6vgTkStwXWE8BPTGDHGPqNPz2mCfSOOYkA99TvCt1bhGPL68zMcwmncMuape10jrI+q4BbHi/FLn31S9z2x5tRhTc+1W506ipM+T3oRD4X+8+qtc4SqFvL0z/Fr14S+Szjis8bz9Lvvaq8cwS/wwGfdlqfPSWqTiFlX77o13u9Ym1PBs8JAytpRoy44X9Ft9E/gvbA+rYKn8NcaaMVc8UHgBw4b9/iqUQZ6hOAJFbyDcUflmsEX4a6+wTtHGfhAIUqa1U29Zc2BytouThD8x6xuN5CtMPi2CrXLZkZf/HyZoRFCP7n+QVR4PV7uI/AjGghN7OU/r1ilnqILtfpNC+o6vIFljBKYlhb/F2AAgaBsWR5wRiIAAAAASUVORK5CYII=);
		color: $alert-red;
		outline-color: $alert-red;
	}

	&.success,
	&.is-valid {
		border-color: $alert-green;
		background-color: $white;
		background-position: 99% center;
		background-repeat: no-repeat;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NDM0NDRERkYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NDM0NDRFMEYwNEIxMUUyOTI4REZGQTEzMzA2MDcyNiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY0MzQ0NERERjA0QjExRTI5MjhERkZBMTMzMDYwNzI2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjY0MzQ0NERFRjA0QjExRTI5MjhERkZBMTMzMDYwNzI2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+7olkTQAAAfhJREFUeNqklU9oE0EUhzdroWjw0tBeWlDxkEaIp55TsCU9VKIgCrHBelA8CQ1Kr1WPbZrQ3gqtQqvGqxpQc2jBk6BIIaAGD4qNCKURpDSKiPi98gLDsJt//uBjsztvfnk7895sIPAw6/joGMThFJyAXn2+A+9gA57/TaY/eU0OeBgfhGm4DiGnsb7DAszxBz/NAdcKjMJLuNWCqagHbsscN5+L+hmH4QkMOe1L5jzFfNA2PgT34ajTuY7AGuZB0/hmh5m+gS0r8xv1zRvg+gGCHZiOwnF4DP3iB3sQkYxPd2C6CWfhB9Xwlus5+K1j4jXuaq3a+gM1H9OPcAa+7q9lPidZJqHbiIm7Wg22rsEI7FrPSzAMX/T+ADyAKSsu7Fr1KplehLvwCs5DvfBf65p+MypqRbO1FXK9utH4/QKuaqYTsG3E3INJv00Q46px3+XxanJ/Ute2/vqP4FKDza2KcdljIKdnhS0xXYULTaqmLMZFn8FFSFtvt6x70ExFCS5oUXspq2ssa7oEl1swFa+CGFdgtkHgPDyDKy02zyxNU6lXRUZb1EuHYayNNs+Yh5B0WQo+/8fpJnNTZFuzz2OpjoQ2QruSOQlMy35fEGmEGMxY9e1brxobw7TkWA1h6xfckUPb+JhGoE/Hpfvew7qUld/H9J8AAwDpw3WYrxcZ3QAAAABJRU5ErkJggg==);
		color: $alert-green;
		outline-color: $alert-green;
	}

	&[disabled],
	&.is-disabled {
		cursor: not-allowed;
		opacity: .6;
		&:focus,
		&:active {
			background-color: $alert-blue;
		}
	}

}

input[type="submit"] {

}

textarea {
	max-width: 100%;
	min-height: 120px;
	line-height: 1.5em;
}

select {
	display: block;
	font-size: 1em;
	margin: 0;
	padding: .2em;
	width: 100%;
}

label {
	display: block;
	margin: 0 0 .6em;
	text-align: left;
}



/* =Form Wraps
==========================================*/
.f-field {
	display: block;
	margin: 0 0 1em;
	position: relative;

	label {
		display: block;
		text-align: left;
	}

}

.f-field--inline {
	align-items: stretch;
	display: flex;
	flex-wrap: nowrap;
	
	input {
		flex-grow: 1;
		flex-shrink: 1;
		height: 63px;
		width: 60%;
	}

	button {
		flex-grow: 0;
		flex-shrink: 0;
	}
	
}



/* =Contact Form 7
==========================================*/
.wpcf7 {
	overflow: hidden;
	position: relative;
}

.screen-reader-response {
	height: 1px;
	left: -100%;
	position: absolute;
	width: 1px;
}

.wpcf7-form-control-wrap {
	display: block;
	position: relative;
}

.wpcf7-not-valid-tip {
	@extend .alert-error;
	border-radius: 0 0 $radius $radius;
	display: block;
}

.wpcf7-validation-errors {
	@extend .alert-error;
	display: block;
	margin-top: 2em;
}

.wpcf7-mail-sent-ng {
	@extend .alert-help;
	display: block;
	margin-top: 2em;
}

.wpcf7-mail-sent-ok {
	@extend .alert-success;
	display: block;
	margin-top: 2em;
}



/* =jQuery UI Select Menus
==========================================*/
.ui-selectmenu-button.ui-button {
	background-color: $lgrey;
	border: 1px solid transparent;
	border-radius: 0;
	box-shadow: 0;
	color: $text-color;
	display: block;
	font-family: $fancy-sans;
	margin: 0;
	padding: .8em 1em;
	position: relative;
	vertical-align: middle;
	width: 100%;

	transition: border-color $transition $easing;

	.ui-selectmenu-icon {
		background-color: $brand-tertiary;
		background-image: url('../images/svgs/icon-arrow-down.svg');
		background-position: center;
		height: 100%;
		right: 0;
		position: absolute;
		top: 8px;
		width: 2.8em;

		.svg & {
			background-size: 17px 9px;
		}

		.no-svg & {
			background-image: url('../images/icon-arrow-down.png');
		}

	}

	.ui-selectmenu-text {

	}

	.disabled & {
		background-color: #c4dfa9;
	}

	&:hover,
	&:focus {
		border-color: $grey;
		border-radius: 0;

	}

	&[aria-expanded="true"] {

		.ui-selectmenu-icon {
			transform: rotate(180deg);
		}

	}

}

.ui-selectmenu-menu {
	background-color: $white;
	border: none;
	box-shadow: none;
	color: $brand-primary;
	font-family: $fancy-sans;
	font-size: 1em;
	margin: 0;
	padding: 0;

	.ui-menu {
		border: none;
		border-radius: 0;
		list-style: none;
		margin: 0;
		max-height: 400px;
		padding: 0;

		> li {
			margin: 0;
		}

	}

	.ui-menu-item {

	}

	.ui-menu-item-wrapper {
		background-color: $white;
		border: none;
		color: $text-color;
		font-family: $fancy-sans;
		padding: .6em .8em;

		&.ui-state-active,
		&.ui-state-focus {
			background-color: $lgrey;
			border: none;
			border-radius: 0;
			color: $text-color;
			margin: 0;
		}

	}

}

.ui-state-active:hover,
.ui-button:active,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active {
	border-color: transparent;
}




@import 'form-newsletter';
