.nav--end {

}

.nav--end--links {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		margin: 0 2em 0 0;
	}

	a,
	a:visited {
		color: $white;
		font-size: .813em;
		font-weight: $bold;

		&:hover,
		&:focus {
			color: $brand-tertiary;
			text-decoration: underline;
		}

	}

}
