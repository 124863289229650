.breadcrumbs {
	color: $brand-primary;
	font-size: .938em;
	margin: 0 0 2em;

	> p {
		margin: 0;

		a,
		a:visited {
			color: $brand-primary;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}

		}

		.breadcrumb_last {

		}

		.seperator {
			padding: 0 .6em;
		}

	}

}
