/* =Base
==========================================*/
html {
	box-sizing: border-box;
	height: 100%;
	margin: 0 !important;
	// NOTE - This is dependant on how the theme is to work. Browser support not perfect.
	scroll-behavior: smooth;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}



/* =Strip basics
==========================================*/
.strip {
	opacity: 1;
	padding: $strip-padding 0;

	transition: opacity $transition $easing;

	// Padding
	&[data-padding="none"],
	&[data-padding="flat"] {
		padding-bottom: 0;
		padding-top: 0;
	}

	&[data-padding="half"] {
		padding-bottom: $strip-padding / 2;
		padding-top: $strip-padding / 2;
	}

	&[data-padding="double"] {
		padding-bottom: $strip-padding * 2;
		padding-top: $strip-padding * 2;
	}

	// Styles
	&[data-style="lined"] {
		padding-bottom: 0;

		&:after {
			background-color: $grey;
			content: '';
			display: block;
			height: 1px;
			margin: $strip-padding / 2 auto 0;
			width: 11.25em;
		}

	}

}



/* =Page heading
==========================================*/
.page--head {
	display: block;
	margin: 0 0 $strip-padding / 3;
}



/* =Filter dropdowns
==========================================*/
.filters--container {

}

.filters--block {
	display: block;
	margin: 2em 0;
}



/* =Map bits
==========================================*/
.map-container {
	margin: $strip-padding / 2 0 0;
}

.temp-map {

	img {
		display: block;
		width: 100%;
	}

}



/* =Single Business
==========================================*/
.business--logo {
	margin: $strip-padding / 2 0;

	img {
		display: block;
		margin: 0 auto;
	}

}

.business--detail--heading {
	font-weight: $bold;
	margin: 0 0 .6em;
}

.business--detail {
	display: block;
	margin: 2em 0;

	> p {
		margin: 0;
	}

	.business--detail--heading {
		font-weight: $bold;
		margin: 0 0 .2em;
	}

	.business--detail--day {
		display: inline-block;
		width: 8em;
	}

	.business--detail--time {

	}

}

.business--buttons {
	display: flex;
	justify-content: flex-start;
	margin: $strip-padding / 2 0 0;

	> .button-wrap {
		margin: 0 2em 0 0;

		&:last-child {
			margin: 0;
		}
	}

}



/* =Overwrites
==========================================*/
.entry-header {
	display: none;
}



@media only screen and (min-width: 50em) {

	@import '800';

}

@media only screen and (min-width: 56.25em) {

	@import '900';

}