.nav--social {

}

.nav--social--links {
	align-items: center;
	display: block;
	display: flex;
	justify-content: flex-start;
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		margin: 0 1em 0 0;

		&:last-child {
			margin: 0;
		}

	}

	a,
	a:visited {
		display: block;
		overflow: hidden;
		position: relative;
		text-decoration: none;

		svg {
			display: block;
			fill: $brand-primary;
			transition: fill $transition $easing;
		}

		&:hover,
		&:focus {

			svg {
				fill: $brand-secondary;
			}

		}

		.social-name {
			height: 1px;
			left: -100%;
			overflow: hidden;
			position: absolute;
			text-transform: uppercase;
			width: 1px;
		}

	}

	.footer & {
		margin: 1.4em 0;

		> li {
			display: block;
			height: 28px;
			margin: 0 1.2em 0 0;
			width: 28px;

			&:last-child {
				margin: 0;
			}
			
		}

		a,
		a:visited {
			align-items: center;
			display: flex;
			height: 28px;
			justify-content: center;
			overflow: visible;
			padding: 0;
			position: relative;
			width: 28px;

			&:before {
				background-color: $white;
				content: '';
				display: block;
				height: 28px;
				left: 0;
				position: absolute;
				top: 0;
				transform: rotate(-45deg);
				width: 28px;
				z-index: 1;

				transition: background-color $transition $easing;
			}

			.social-icon {
				position: relative;
				z-index: 2;
			}

			svg {
				fill: $brand-secondary;
			}

			&:hover,
			&:focus {

				&:before {
					background-color: $brand-tertiary;
				}

			}

		}

	}

}



@media only screen and (min-width: 43.75em) {

	@import 'nav-social_700';

}
