/* =WP Gallery Styling
==========================================*/
.gallery {
	grid-template-columns: repeat(2, 1fr);
}

.no-cssgrid {

	.gallery-columns-2,
	.gallery-columns-3,
	.gallery-columns-4 {

		.gallery-item {
			width: 45%;
		}

	}

}
