/* =WP Gallery Styling
==========================================*/
.gallery {

	&.gallery-columns-2 {

	}

	&.gallery-columns-3 {
		grid-template-columns: repeat(3, 1fr);
	}

	&.gallery-columns-4 {
		grid-template-columns: repeat(4, 1fr);
	}

}

.no-cssgrid {

	.gallery-columns-2 {

		.gallery-item {
			width: 45%;
		}

	}

	.gallery-columns-3 {

		.gallery-item {
			width: 30%;
		}

	}

	.gallery-columns-4 {

		.gallery-item {
			width: 20%;
		}

	}

}
