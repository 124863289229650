.nav--primary {
	margin: 1.4em 0 0;
	width: 100%;
}

.nav--primary--links {
	display: flex;
	justify-content: space-between;
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: block;
		margin: 0 1em;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}

	}

	a,
	a:visited {
		font-size: .875em;
		font-weight: $bold;
		line-height: 1.2;
	}

}



@media only screen and (min-width: 43.75em) {

	@import 'nav-primary_700';

}
