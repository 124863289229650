.footer {
	
}

.footer--inner {
	align-items: flex-start;
	display: flex;
	justify-content: space-between;
}



/* =Copyright and Cargo Logo
==========================================*/
.copyright {
	
}

.site-by {
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: 2em;
}