/* =Theme url
==========================================*/
$url:					'/wp-content/themes/javo-directory/library/';



/* =Colour Variables
==========================================*/
$alert-yellow:			#ebe16f;
$alert-red:				#fbe3e4;
$alert-green:			#e6efc2;
$alert-blue:			#d5edf8;

$black:					#000;
$white:					#fff;
$grey:					#707070;
$lgrey:					#F2F2F2;

$text-color:			#333333;

$brand-primary:			#004AAD;
$brand-secondary:		#161C5E;
$brand-tertiary:		#00A8E5;

$link-color:			$brand-primary;
$link-hover:			darken($link-color, 9%);



/* =Social colours
==========================================*/
$social-facebook:		#4d70a8;
$social-twitter:		#01baf2;
$social-google:			#e3401d;
$social-linkedin:		#1687b1;



/* =Wrap Width
==========================================*/
$wrap-restrict:			65.938em;
$wrap-break:			$wrap-restrict + 3em;
$wrap-tight:			49.8em;



/* =Strip Padding
==========================================*/
$strip-padding:			5.625em;



/* =Site Transitions
==========================================*/
$transition:			.25s;
$easing: 				ease-in-out;



/* =Radius
==========================================*/
$radius:				.4em;



/* =Font Families
==========================================*/
// font stacks
$sans-serif:        	"Helvetica Neue", Helvetica, Arial, sans-serif;
$serif:             	"Georgia", Cambria, Times New Roman, Times, serif;

$fancy-sans:			'Montserrat', $sans-serif;
$fancy-serif:			$serif;



/* =Font weights in use from Typekit or Google Fonts
==========================================*/
$light:					300;
$regular:				500;
$italic:				500;
$bold:					700;