.article-list {

}



/* =Listed Article
==========================================*/
.article--listed {
	border: 1px solid $brand-primary;
	border-width: 0 0 1px;
	padding: 2em 0;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		border-color: transparent;
		padding-bottom: 0;
	}

}

.article--heading {
	color: $brand-primary;
	font-size: 1.563em;
	font-weight: $bold;
	margin: 0;

	a,
	a:visited {
		color: $brand-primary;

		transition: color $transition $easing;

		&:hover,
		&:focus {
			color: $brand-secondary;
		}

	}

}

.article--date {
	border-bottom: 1px solid $brand-primary;
	color: $brand-primary;
	font-size: 1.25em;
	margin: .5em 0 1em;
	padding: 0 0 1em;
}

.article--content {
	font-size: 1.25em;
}

.article--more {

	[data-style="webinar"] & {

		a,
		a:visited {
			color: $brand-secondary;

			svg {
				fill: $brand-secondary;
			}

			&:hover,
			&:focus {
				color: $brand-primary;

				svg {
					fill: $brand-primary;
				}

			}

		}

	}

}
