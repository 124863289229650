.footer {
	background-color: $brand-secondary;
	color: $white;
	display: block;
	padding: 2em 0;
}

.footer--inner {
	margin: 0 0 1em;

	&:last-child {
		margin: 0;
	}
	
}



/* =Copyright and Cargo Logo
==========================================*/
.copyright,
.site-by {
	color: #CEC7C0;
	font-size: .813em;

	.footer-link{
		color:white;
	}
}

.copyright {
	
}

.site-by {
	align-items: flex-end;
	display: flex;
	justify-content: flex-start;
}

.cargo-logo,
.cargo-logo:visited {
	display: block;
	margin-left: .3em;
	position: relative;
	top: 2px;

	svg {
		display: block;
		fill: $white;

		transition: fill $transition $easing;
	}

	&:hover,
	&:focus {

		svg {
			fill: $brand-tertiary;
		}

	}

}



@media only screen and (min-width: 43.75em) {

	@import 'footer_700';

}
