.form--newsletter {

	br {
		display: none;
	}

    label {
        text-align: center;
    }

    input[type="text"],
    input[type="email"] {
        margin: 1em 0;
    }

    button {
        display: block;
        margin: 0 auto;
    }

}


@media only screen and (min-width: 43.75em) {

    @import 'form-newsletter_700';

}

@media only screen and (min-width: 62.5em) {

    @import 'form-newsletter_1000';

}
