.nav--social {

}

.nav--social--links {

	li {

	}

	a,
	a:visited {

	}

	.footer & {
		margin: 0 0 1em;

		> li {

		}

		a,
		a:visited {

		}

	}

}
