/* =Flexslider
==========================================*/
@import 'flexslider';

body {

	/* =Flexslider Overwrite
	==========================================*/
	.flexslider {
		background-color: transparent;
		border-radius: 0;
		border-width: 0;
		box-shadow: none;
		display: block;
		margin: 0;
		width: 100%;

		.flex-viewport {
			height: 100%;
		}

		.slides {
			height: 100%;
		}

		.slide {
			height: 100%;
		}

	}



	/* =FlexNav
	==========================================*/
	.flex-direction-nav {
		bottom: 1em;
		display: block;
		overflow: hidden;
		position: absolute;
		right: 1em;

		.flex-nav-prev,
		.flex-nav-next {
			display: block;
			margin: 0;
		}

		.flex-nav-prev {
			float: left;
		}

		.flex-nav-next {
			float: right;
			margin-left: .4em;
		}

		.flex-prev,
		.flex-next {
			background-color: $brand-primary;
			background-position: center;
			background-repeat: no-repeat;
			display: block;
			height: 46px;
			left: 0;
			margin: 0;
			opacity: 1;
			position: relative;
			text-indent: -999em;
			text-align: left;
			top: 0;
			width: 46px;

			&:before {
				content: '';
				display: none;
			}

			&:hover,
			&:active {
				background-color: $brand-secondary;
			}

			&.flex-disabled {

				&:hover,
				&:focus,
				&:active {
					background-color: #B2C7D5;
				}

			}

		}

		.flex-prev {
			background-image: url('../images/svgs/icon-chevron-left.svg');
		}

		.flex-next {
			background-image: url('../images/svgs/icon-chevron-right.svg');
		}

		.flex-disabled {
			background-color: #B2C7D5;
			cursor: not-allowed;
		}

	}

	.flex-control-nav {
		bottom: 2em;
		display: flex;
		justify-content: center;
		left: 0;
		width: 100%;

		> li {
			display: block;
				margin: 0 .3em;

			a,
			a:visited {
				background-color: $white;
				border-radius: 0;
				box-shadow: none;
				display: block;
				height: 12px;
				opacity: .5;
				width: 12px;

				&:hover,
				&:focus {
					background-color: $white;
					opacity: 1;
				}

				&.flex-active {
					background-color: $white;
					opacity: 1;
				}

			}

		}

	}



	/* =Banner Slider
	==========================================*/
	.slider-banner {

		.slider--image {
			padding-bottom: 35%;

			img {
				display: none;
			}

		}

	}



	/* =Single business slider
	==========================================*/
	.slider-business {

		.slider--image {
			padding-bottom: 100%;

			img {
				display: none;
			}
			
		}

	}

}