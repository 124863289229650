nav.pagination {
	margin: $strip-padding 0;

	> .page-numbers {
		align-items: center;
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			margin: 0 1em;

			> a,
			> a:visited,
			> span {
				font-size: 1em;
			}

			> span {
				color: $text-color;
			}

			> a,
			> a:visited {
				color: $brand-primary;
				text-decoration: none;

				transition: color $transition $easing;

				&:hover,
				&:focus {
					color: $brand-secondary;
				}

				&.prev,
				&.next {
					color: $brand-primary;

					&:hover,
					&:focus {
						color: $brand-secondary;
					}

				}

			}

		}

	}

}
