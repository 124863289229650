%btn {
	background-color: $brand-primary;
	border: 0;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-family: $fancy-sans;
	font-size: .875em;
	font-weight: $bold;
	padding: .8em 1em;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;

	transition: background-color $transition $easing,
				color $transition $easing;

	&:hover,
	&:focus {
		background-color:$brand-secondary;
		color: $white;
		text-decoration: none;
	}

	&:active {

	}

}

.button-wrap {

	a,
	a:visited {
		@extend %btn;
	}

}

button {
	@extend %btn;
	background-color: $brand-tertiary;
	border-width: 0;

	&:hover,
	&:focus {

	}
	
}
