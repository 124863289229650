.nav--primary {
	margin: 0 0 1em;
	width: auto;
}

.nav--primary--links {
	

	li {
		margin: 0 1.6em;
	}

	a,
	a:visited {
		
	}

}
