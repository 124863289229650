.wrap {
	margin: 0 auto;
	padding: 0 5%;

	@media only screen and (min-width: $wrap-break) {
		max-width: $wrap-restrict;
		padding-left: 0;
		padding-right: 0;
	}

}

.wrap--tight {
	display: block;
	margin: 0 auto;
	max-width: $wrap-tight;
}