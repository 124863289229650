.mast {

	.wrap {
		align-items: flex-end;
		// display: block;
		padding-bottom: 1em;
		padding-top: 1em;
		position: relative;
	}

}

.logo {

}

.mast--extra {
	position: absolute;
	right: 5%;
	top: 0;

	@media only screen and (min-width: $wrap-break) {
		right: 0;
	}

	.button-wrap {
		
	}
	
}
