table {
	border-collapse: collapse;
	margin: 2em 0;
	width: 100%;

	th,
	td {
		padding: 1.2em 1em;
	}

	th {
		background-color: $brand-primary;
		font-family: $fancy-sans;
		color: $brand-secondary;
		font-size: 1em;
		text-align: left;
		text-transform: uppercase;
	}

	td {
		font-size: 1em;
	}

	tr {

		td,
		th {
			border-bottom: 2px solid darken($brand-primary, 2%);
		}

		&:last-child {

			td,
			th {
				border-width: 0;
			}

		}

		&:nth-child(odd){
			background-color: lighten($brand-tertiary, 10%);
		}

	}

}
