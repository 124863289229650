.form--newsletter {
    flex-wrap: nowrap;

    label {
        flex-grow: 0;
        flex-shrink: 0;
        margin: 0 2em 0 0;
        text-align: left;
        width: auto;
    }

    input[type="text"],
    input[type="email"] {

    }

    button {
        margin: 0 0 0 2em;
    }

}
