/* =Page footer gallery styling
==========================================*/
.gallery--wrap {

}

.gallery--image {
	background-attachment: inherit;
	padding-bottom: 42%; // Aspect ratio 7:3 ( 3 / 7 * 100 )
}

.gallery-caption {
	font-size: .85em;
	text-align: left;
}


/* =WP Gallery Styling
==========================================*/
.gallery {
	display: grid;
	grid-column-gap: $gutter-width;
	grid-row-gap: $gutter-width;
	grid-template-columns: 1;
	margin: 2em 0;

	br {
		display: none;
	}

}

.gallery-item {
	display: block;
	text-align: center;

	img {
		display: block;
		height: auto;
	}

}

.no-cssgrid {

	.gallery {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.gallery-columns-2,
	.gallery-columns-3,
	.gallery-columns-4 {

		.gallery-item {
			display: block;
			margin: 1.5%;
			text-align: center;
			width: 100%;
		}

	}

}

@media only screen and (min-width: 37.5em) {

	@import 'image-gallery_600';

}

@media only screen and (min-width: 50em) {

	@import 'image-gallery_800';

}
