/*

	url variable usage example:

		background-image: url('#{$url}/images/...');

*/



.mast {
	display: block;

	.wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 2em;
		padding-top: 2em;
	}

}

.logo {

	img,
	svg {
		display: block;
	}

	svg {
		@media only screen and (max-width: 50em) {
			height: 67px;
			width: 204px;
		}
	}

}

.mast--extra {
	align-items: center;
	display: flex;
	justify-content: flex-end;

	.button-wrap {
		margin: 0 0 0 1.4em;
	}

}



@media only screen and (min-width: 43.75em) {

	@import 'mast_700';

}
